var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "log" },
    [
      _c("app-header", { attrs: { title: "查看日志", isShowBack: true } }),
      _c("div", { staticClass: "log_content" }, [
        _vm._v("本次已导入"),
        _c("span", [_vm._v(_vm._s(_vm.leaseCount))]),
        _vm._v("条排房租约信息，成功导入："),
        _c("span", { staticStyle: { color: "#30BAC1" } }, [
          _vm._v(_vm._s(_vm.planSuccessCount))
        ]),
        _vm._v("人，失败："),
        _c("span", { staticStyle: { color: "red" } }, [
          _vm._v(_vm._s(_vm.planFailCount))
        ]),
        _vm._v("\n        人。请在租约列表中查看导入的租约排房信息。")
      ]),
      _c(
        "div",
        {
          directives: [
            {
              name: "show",
              rawName: "v-show",
              value: _vm.showErrorList,
              expression: "showErrorList"
            }
          ],
          staticClass: "log_list"
        },
        [
          _c("div", { staticClass: "log_list_tip" }, [
            _vm._v(
              _vm._s(_vm.createdDate) +
                " 批量导入排房日志，以下数据导入时出错，请检查更新后重试："
            )
          ]),
          _c("app-list", {
            ref: "myList",
            attrs: { opt: _vm.opt },
            on: { get: _vm.onGet }
          })
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }